<template>
  <v-container fluid>
    <!-- {{ mapMarkers }} -->
    <section>
      <l-map
        class="VueLeafMap"
        :zoom="zoom"
        :center="MapCenter"
        :bounds="bounds"
      >
        <!-- :bounds="bounds" -->
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />
        <!-- Markers  -->
        <template>
          <!-- Start and End Marker -->
          <l-marker
            v-for="(points, i) in mapMarkers"
            :key="i"
            :lat-lng="points"
          >
            <l-icon v-if="i == 0 || i == mapMarkers.length - 1">
              <v-icon
                v-if="i == 0"
                color="#000"
                x-large
              >
                mdi-google-maps
              </v-icon>
              <Icons
                v-else
                name="marker-end"
              />
            </l-icon>
            <l-icon v-else>
              <Icons
                :name="`marker-${i - 1}`"
                variant="marker"
              />
            </l-icon>
          </l-marker>
          <!-- <l-marker
            v-if="i == 0 || i == mapMarkers.length - 1"
            :key="i"
            :lat-lng="points"
          >
            <l-icon v-if="i !== 0">
              <Icons name="marker-end" />
            </l-icon>
            <l-icon v-if="i == 0">
              <v-icon
                color="#000"
                x-large
              >
                mdi-google-maps
              </v-icon>
            </l-icon>
          </l-marker> -->
          <!-- Drop off Markers -->
          <!-- <l-marker
            v-else-if="i > 1"
            :key="i"
            :lat-lng="points"
          /> -->
        </template>

        <!-- Markers  -->
        <!-- Polyline -->
        <l-polyline
          :lat-lngs="routeCoordinates"
          :color="'green'"
        />
        <!-- Polyline -->
        <!--  -->
        <l-control>
          <v-btn
            v-if="isModelId()"
            color="primary"
            dark
            @click="openAddPathModal"
          >
            {{ $t("add_path") }}
          </v-btn>
        </l-control>
      </l-map>
    </section>
    <add-path-modal
      v-if="isPathModal"
      :is-visible="isPathModal"
      :toggle="togglePathModal"
      @progress="isModelId"
    />
  </v-container>
</template>
<script>
import { latLngBounds } from "leaflet";

import {
  LMap,
  LTileLayer,
  LControl,
  LMarker,
  LPolyline,
  LIcon,
  //   LPopup,
} from "vue2-leaflet";
import { mapGetters } from "vuex";
export default {
  components: {
    LMap,
    LTileLayer,
    LControl,
    LMarker,
    LIcon,
    LPolyline,
    Icons: () => import("@/components/base/icons.vue"),
    AddPathModal: () => import("./dialogs/AddPath.vue"),

    // LPopup,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=en",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 8,
      //
      isPathModal: false,
      mapMarkers: [],

      iconSize: 40,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      optRoutes: "truckistanRoute/getRoute",
    }),

    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },

    bounds() {
      let arr = [];
      if (this.routeCoordinates.length > 0) {
        // const routeLength = this.routeCoordinates.length - 1;
        // arr.push(this.routeCoordinates[0]);
        arr.push(this.routeCoordinates);
      }
      //   defual location when nothing is selected
      else {
        arr.push([24.8785, 67.0641]);
      }
      return latLngBounds(arr);
    },

    routeCoordinates() {
      const mrk = [];
      const r = [];
      this.optRoutes?.geometry.forEach((el) => {
        // start point
        if (el.planStartPoint) {
          const cor = el?.planStartPoint?.coordinate;
          const sPnt = [cor?.latitude, cor?.longitude];
          mrk.push(sPnt);
          r.push(sPnt);
        }
        // start point
        // drop off
        if (el.plannedStops) {
          el.plannedStops.forEach((ps) => {
            if (ps?.geomToNextStop?.coordinates) {
              ps?.geomToNextStop?.coordinates.forEach((cor, i) => {
                const corr = [...cor].reverse();
                if (i == 0) {
                  mrk.push(corr);
                }
                r.push(corr);
              });
            }
          });
        }
        // drop off
        // end point
        if (el.planEndPoint) {
          const cor = el?.planEndPoint?.coordinate;
          const ePnt = [cor?.latitude, cor?.longitude];
          mrk.push(ePnt);
          r.push(ePnt);
        }
        // end point
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.mapMarkers = mrk;
      return r;
    },

    MapCenter() {
      //   return this.routeCoordinates[0] ?? [24.8785, 67.0641];
      return [24.8785, 67.0641];
    },
  },
  async mounted() {
    // for get Route Loction Dropdown
    if (this.authUser.isAdmin) {
      // const value
    } else {
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("truckistanRoute/getLocations", params);
    }
  },
  methods: {
    isModelId() {
      return localStorage.getItem("modelId") ? false : true;
    },
    fetchData() {},
    openAddPathModal() {
      this.isPathModal = true;
    },
    togglePathModal(v) {
      this.isPathModal = false;
      if (v == "save") {
        this.mapMarkers = [];
        this.$store.commit("truckistanRoute/SET_ROUTE", null);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.VueLeafMap
    z-index: 1 !important
    height: 80vh
</style>
